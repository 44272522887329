import React, { FC } from 'react'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { CrownWhiteSvg } from 'components/page/list/icons/CrownWhiteSvg'
import { Indent } from 'constants/sizes'
import { ListItem } from 'components/page/list/ListItem'
import { definitions } from 'api/generated/masked_ads'
import { styled } from 'linaria/react'

const Vip: FC = ({ children }) => (
  <ItemVip data-name="announce-vip">
    <MarkedItemVip data-name="marked-">
      <CrownWhiteSvg />
    </MarkedItemVip>
    {children}
  </ItemVip>
)

const Ordinary: FC = ({ children }) => (
  <ItemOrdinary data-name="announce-ordinary">{children}</ItemOrdinary>
)

export const List: FC<{ items: definitions['Ad'][] }> = ({ items }) => {
  return (
    <ListWrapper data-name="announces-similar-list">
      {items.map((item) => {
        const listItem = <ListItem item={item} />

        if (item.isVip) {
          return <Vip key={item.id}>{listItem}</Vip>
        } else {
          return <Ordinary key={item.id}>{listItem}</Ordinary>
        }
      })}
    </ListWrapper>
  )
}

export const itemCss = `
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;  
  background-color: white;  
  margin-top: ${Indent.indent20}px;   
  
  &:last-child {
   margin-bottom: ${Indent.indent12}px
  }
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }
  }  
`

export const MarkedItemVip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 25px;
  width: 28px;
  left: 0;
  top: 0;
  background-color: ${ColorsTheme.backgroundIcon};
  border-radius: 7px 0 10px 0;
  pointer-events: none;
`

export const ListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
`

export const ItemOrdinary = styled.li`
  ${itemCss};
  border: 2px solid transparent;
`

export const ItemVip = styled.li`
  ${itemCss};
  border: 2px solid #fed576;
`
