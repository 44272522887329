import { convertToDayMonthYear } from 'functions/convertDate'
import { definitions } from 'api/generated/masked_ads'
import { ordinaryAnnounceList } from 'reducers/announce/announceListReducer'

export const groupDateAnnounceOrdinary = (
  announces: definitions['Ad'][]
): ordinaryAnnounceList[] => {
  return announces.reduce<ordinaryAnnounceList[]>((acc, item) => {
    if (item.publicationTime) {
      const findIndex = acc.findIndex(({ date }) => {
        return (
          convertToDayMonthYear(date) ===
          convertToDayMonthYear(Number(item.publicationTime))
        )
      })
      if (findIndex > -1) {
        const _acc = [...acc]
        _acc[findIndex].items.push(item)

        return [..._acc]
      }

      return [...acc, { date: Number(item.publicationTime), items: [item] }]
    }

    return acc
  }, [])
}
