import {
  LocationForm,
  findLocation,
  findLocationWithRegion,
} from 'functions/findLocation'
import { MetaWithTitle } from 'common/types'
import { findMetaInfoForAnnounces } from 'functions/findMetaInfoForAnnounces'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/**
 * Для страницы списка объявлений
 */
export const useMetaDataAnnounces = (): MetaWithTitle => {
  const intl = useIntl()

  const { whoIsSeeker, whoLookingFor, minAge, maxAge, location, tags } =
    useShallowEqualSelector(
      ({
        announceForm: {
          defaultForm: {
            whoIsSeeker,
            whoLookingFor,
            minAge,
            maxAge,
            location,
            tags,
          },
        },
      }) => ({
        whoIsSeeker,
        whoLookingFor,
        minAge,
        maxAge,
        location,
        tags,
      })
    )

  return useMemo(() => {
    const mainTag = tags[0]

    const _datingGoal = tags[0] ? mainTag?.title : ''

    const _locationCity = findLocation(location as LocationForm)
    const _locationWithRegion = findLocationWithRegion(location as LocationForm)

    const { pageTitle, metaTitle, metaDescription } = findMetaInfoForAnnounces(
      intl,
      _datingGoal,
      _locationCity,
      _locationWithRegion,
      whoIsSeeker,
      whoLookingFor,
      minAge,
      maxAge
    )

    return {
      pageTitle,
      metaTitle,
      metaDescription,
    }
  }, [intl, location, maxAge, minAge, tags, whoIsSeeker, whoLookingFor])
}
