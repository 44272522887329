import React, { FC, useCallback } from 'react'
import cookie from 'js-cookie'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { Cookies } from 'constants/cookie'
import { FontSize } from 'constants/sizes'
import { Form } from 'components/presentational/form/Form'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Paths } from 'components/components.paths'
import { PrimaryInputButton } from 'components/presentational/announce/buttons/PrimaryButton'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { styled } from 'linaria/react'
import { updateCookiesAction } from 'actions/system/setBootstrapDataAction'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useTheme } from 'hooks/useTheme'
import { zIndex } from 'components/presentational/zIndex'

const messages = defineMessages({
  submit: {
    id: 'confirmation.modal.confirm',
    defaultMessage: 'Мне есть 18 лет',
  },
})

const AgeVerificationModal: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { language, confirmAgePopUpShown } = useShallowEqualSelector(
    ({
      system: {
        language,
        cookies: { confirmAgePopUpShown },
      },
    }) => ({
      language,
      confirmAgePopUpShown,
    })
  )

  const handleSubmit = useCallback(async () => {
    cookie.set(Cookies.confirmAgePopUpShown, JSON.stringify(true))
    dispatch(updateCookiesAction(Cookies.confirmAgePopUpShown, true))
    await Promise.resolve()
  }, [dispatch])

  const theme = useTheme()

  if (confirmAgePopUpShown) {
    return null
  }

  return (
    <Overlay>
      <Modal
        style={{
          [theme.left]: '50%',
          transform: `translate(${-50 * theme.multiplier}%, -50%)`,
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Title>
            <FormattedMessage
              id="confirmation.modal.title"
              defaultMessage="Masked Love - анонимные знакомства"
            />
          </Title>
          <Description>
            <FormattedMessage
              id="confirmation.modal.description"
              defaultMessage="Здесь не требуется регистрация, загрузка фотографий, заполнение анкеты и ее подтверждение. На сайте публикуются анонимные объявления о знакомствах. Все общение идет через email. Ввиду анонимности, на сайте много объявлений секс тематики."
            />
          </Description>
          <p>
            <FormattedMessage
              id="confirmation.modal.confirm-text"
              defaultMessage="Пожалуйста подтвердите, что вам есть 18 лет."
            />
          </p>
          <PrimaryInputButton
            type="submit"
            value={intl.formatMessage(messages.submit)}
          ></PrimaryInputButton>
          <Agreement>
            <FormattedMessage
              id="confirmation.modal.agreement.text"
              defaultMessage="Заходя на этот сайт, вы соглашаетесь с {agreementLink}."
              values={{
                agreementLink: (
                  <AgreementLink
                    to={mergeAllUrls(language?.code, Paths.agreement)}
                  >
                    <FormattedMessage
                      id="confirmation.modal.agreement.link"
                      defaultMessage="условиями использования"
                    />
                  </AgreementLink>
                ),
              }}
            />
          </Agreement>
        </Form>
      </Modal>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.ageVerificationPopUp};
  background-color: rgba(0, 0, 0, 0.5);
`

const Modal = styled.div`
  position: relative;
  top: 50%;
  width: min(500px, 95%);
  border: 2px solid ${ColorsTheme.primaryActive};
  border-radius: 5px;
  background-color: ${ColorsTheme.black};
  padding: 20px;
  box-sizing: border-box;
  color: ${ColorsTheme.white};

  input {
    width: 100%;
  }
`

const Title = styled.h2`
  margin-top: 0;
`

const Description = styled.p`
  margin: 0;
`

const Agreement = styled.p`
  font-size: ${FontSize.smallLabel}px;
  margin-bottom: 0;
`

const AgreementLink = styled(Link)`
  font-size: ${FontSize.smallLabel}px;
  color: ${ColorsTheme.primaryDefaultLight};
`

export default AgeVerificationModal
