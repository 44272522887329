import Pagination from 'components/block/Pagination/Pagination'
import React, { FC, useMemo } from 'react'
import { AnnounceListParams } from 'components/components.paths'
import { ErrorBoundary } from 'components/system/ErrorBoundary'
import { Indent } from 'constants/sizes'
import { Spacer } from 'components/presentational/spacer'
import { firstPageListAnnounces } from 'actions/announce.function'
import { limit } from 'api/announce/searchAnnounceListApi'
import { useBaseUrlWithoutPage } from 'components/page/list/hooks/useBaseUrlWithoutPage'
import { useParams } from 'react-router-dom'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const AnnouncesListPagination: FC = () => {
  const { total } = useShallowEqualSelector(({ announceList: { total } }) => ({
    total,
  }))

  const { page } = useParams<AnnounceListParams>()

  const match = useBaseUrlWithoutPage()

  const _currentPage = useMemo(
    () => Number(page ? page : firstPageListAnnounces),
    [page]
  )

  if (total && match && match.url) {
    return (
      <ErrorBoundary place="pagination">
        <Spacer height={Indent.indent20} />
        <Pagination
          currentPage={_currentPage}
          totalCount={total}
          pageSize={limit}
          siblingCount={1}
          href={match.url}
        />

        <Spacer height={Indent.indent20} />
      </ErrorBoundary>
    )
  }

  console.error('url for pagination not found')

  return null
}
