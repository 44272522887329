import {
  AnnounceListTypes,
  CLEAR_ANNOUNCES_LIST,
  FETCH_ANNOUNCE_LIST,
} from 'actions/announce/announceListAction'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { Reducer } from 'redux'
import { defaultPromiseReducer } from 'reducers/system/defaultPromiseReducer'
import { definitions } from 'api/generated/masked_ads'
import {
  filterAnnounceOrdinary,
  filterAnnouncesVip,
} from './function/separateAnnounces'
import { firstPageListAnnounces } from 'actions/announce.function'
import { groupDateAnnounceOrdinary } from './function/groupDateAnnounceOrdinary'
import { initialStateReducer } from 'reducers/system/initialStateReducer'
import { limit } from 'api/announce/searchAnnounceListApi'

export interface ordinaryAnnounceList {
  date: number
  items: definitions['Ad'][]
}

export interface AnnounceListState {
  ordinaryAnnounces: ordinaryAnnounceList[]
  vipAnnounces: definitions['Ad'][]
  announcesLoading: boolean
  items: definitions['Ad'][]
  total: number
  storePage?: number
}

const initialState: AnnounceListState = {
  ordinaryAnnounces: [],
  vipAnnounces: [],
  items: [],
  announcesLoading: false,
  total: 0,
  storePage: undefined,
}

export const announceListReducer: Reducer<
  AnnounceListState,
  AnnounceListTypes | RestoreInitialStateAction
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANNOUNCE_LIST:
      return defaultPromiseReducer<typeof state, typeof action.result>(
        state,
        action,
        () => ({
          announcesLoading: true,
        }),
        (result) => {
          if (result) {
            const _announcesVip = filterAnnouncesVip(result.ads)

            const _announcesOrdinary = filterAnnounceOrdinary(result.ads)

            const _groupDateAnnounceOrdinary =
              _announcesOrdinary && _announcesOrdinary.length > 0
                ? groupDateAnnounceOrdinary(_announcesOrdinary)
                : []

            const _offset = action.offset
            const _page = _offset / limit
            const storePage = _page === 0 ? 0 : _page + firstPageListAnnounces

            return {
              ordinaryAnnounces: _groupDateAnnounceOrdinary,
              vipAnnounces: _announcesVip,
              announcesLoading: false,
              items: result.ads,
              total: result.totalNumber,
              storePage,
            }
          }

          return state
        },
        () => ({
          announcesLoading: false,
        })
      )

    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'announceList')

    case CLEAR_ANNOUNCES_LIST:
      return {
        ...initialState,
        announcesLoading: true,
      }
  }

  return state
}
